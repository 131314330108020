.rich-text {
  text-align: center;

  &__header {
    margin-bottom: 20px;
  }

  &__heading {
    font-size: font-size(55px);

    @include media-query(sm) {
      font-size: font-size(125px);
    }
  }

  &__text {
    font-size: font-size(16px);

    @include media-query(sm) {
      font-size: font-size(26px);
    }
  }

  &--left {
    text-align: left;
  }

}

* {
  box-sizing: inherit;
  margin: 0;
}

html {
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
}

svg {
  display: block;
  max-width: 100%;
}

.svg-fill-current-color {
  fill: currentColor;
}

.svg-stroke-current-color {
  stroke: currentColor;
}

button {
  border: none;
  box-shadow: none;
  outline: none;

  -webkit-appearance: none;
  background: none;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

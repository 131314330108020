.announcement-bar {
  padding: 10px 0;

  background-color: get-color(primary);

  text-align: center;

  color: get-color(on-primary);
  font-size: font-size(14px);

  &__section {
    &--left {
      @include media-query(sm) {
        text-align: left;
      }
    }

    &--right {
      @include media-query(sm) {
        text-align: right;
      }
    }
  }
}

.image {
  position: relative;

  border-radius: $img-border-radius;
  padding-bottom: $img-ratio-padding;

  width: 100%;
  height: 0;

  overflow: hidden;

  background-color: get-color(background-pale);

  &--square {
    padding-bottom: 100%;
  }

  &--video-thumbnail {
    padding-bottom: 55.8%;
  }

  &--video-thumbnail-large {
    padding-bottom: 177%;
    background-color: get-color(black);
  }

  &--background-color {
    background-color: get-color(product-image-background);
  }

  &__object {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  &__badges {
    position: absolute;
    display: grid;
    gap: 4px;
    justify-items: right;
    z-index: 1;
    top: 8px;
    right: 8px;
  }
}

$spacer-1: 5px;
$spacer-2: 10px;
$spacer-3: 15px;
$spacer-4: 20px;

@import './markdown.scss';
@import './headings.scss';
@import './lists.scss';
@import './tables.scss';
@import './images.scss';
@import './code.scss';
@import './blob-csv.scss';
@import './footnotes.scss';

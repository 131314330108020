.hero-slider {
  $this: &;

  &--move-top {
    margin-top: -$header-height;

    @include media-query(sm) {
      margin-top: -$header-height-sm;
    }
  }

  &__content-wrapper {
    position: relative;
    text-align: center;
    padding: 45px 0;
  }

  &__logo-image {
    width: 100%;
    max-width: 500px;
    display: inline-block;
  }

  &__video {
    object-fit: cover;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    height: 100%;

    @include media-query(sm) {
      padding: 0 70px;
    }

    &--center {
      align-items: center;
    }

    &--right {
      align-items: center;

      @include media-query(sm) {
        align-items: flex-end;
      }
    }
  }

  &__heading,
  &__subtitle {
    color: get-color(white);

    text-align: center;

    @include media-query(sm) {
     text-align: initial;
    }
  }

  &__heading {
    text-transform: initial;
    font-size: font-size(32px);
    text-align: center;
    display: inline-block;
    padding: 25px 0;
    color: var(--on-background);


    max-width: 720px;
  }

  &__cta {
    .button {
      background-color: #25d366;
      text-transform: uppercase;
      display: inline-flex;
      gap: 9px;
    }
  }
}
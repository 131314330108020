.post-tile {
  $this: &;
  display: block;
  position: relative;
  border-radius: $img-border-radius;
  //border: 1px solid rgba(get-color(on-background-rgb), 0.2);
  overflow: hidden;
  max-height: 460px;
  min-height: 100%;

  &:hover {
    #{$this}__text {
      &--overlay {
        p {
          color: get-color(primary);
        }
      }
    }

    #{$this}__locked {
      .button {
        opacity: 0.8;
      }
    }
  }

  &__empty {
    margin: 8px;

    @include media-query(sm) {
      margin: 16px;
    }
  }

  &__link {
    position: absolute;
    inset: 0;
    z-index: 2;
  }

  &__date {
    font-size: font-size(14px);
    color: rgba(get-color(on-background-rgb), 0.6);
  }

  &__content {
    color: get-color(text);
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }

  &__title-post {
    line-height: 140%;
    margin-top: 8px;
    font-weight: 700;
  }

  &__container {
    display: grid;
    grid-template-columns: .5fr .5fr;
    align-items: center;
    height: 100%;

    a {
      position: relative;
      z-index: 2;
    }
  }

  &__images {
    $this-2: &;

    &--overlay {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 50%;
      background-color: rgba(get-color(black), 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: font-size(24px);
      font-weight: 700;
      line-height: 130%;
      color: get-color(white);
    }

    &--image {
      height: 100%;
      object-fit: cover;
    }

    &--2,
    &--4,
    &--5 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      min-height: 100%;
      gap: 2px;
    }

    &--3 {
      #{$this-2}--image {
        position: absolute;

        &-1 {
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          padding: 0 1px 0 0;
        }

        &-2 {
          top: 0;
          right: 0;
          width: 50%;
          height: 50%;
          padding: 0 0 1px 1px;
        }

        &-3 {
          bottom: 0;
          right: 0;
          width: 50%;
          height: 50%;
          padding: 1px 0 0 1px;
        }
      }
    }
  }

  &__image {
    &-container {
      position: relative;
      width: 100%;

      .empty {
        padding-bottom: 55.8%;
      }
    }
  }

  &__text {
    &--overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 50px 24px 24px;
      left: 0;
      z-index: 1;
      background: linear-gradient(to top, get-color(background) 55%, rgba(0, 0, 0, 0) 100%);

      p {
        color: rgba(get-color(on-background-rgb), 0.5);
      }
    }
  }

  .image {
    border-radius: 0;
    background: none;

    svg {
      width: 100px;
      height: 100px;
      fill: get-color(black);
    }

    &--video-thumbnail {
      padding-bottom: 100%;
    }

    #{$this}__image {
      &--video-play {
        position: absolute;

        svg {
          width: 64px;
          height: 64px;
          padding: 20px;
          background-color: rgba(get-color(black), 0.4);
          border-radius: 50%;
          overflow: visible;
        }
      }

      &--audio-background {
        position: absolute;
        z-index: -1;
      }

      &--audio-image {
        width: 60%;
        padding: 20px;
        max-width: 250px;

        @include media-query(sm) {
          padding: 0;
          max-width: 186px;
        }
      }

      &--audio-play {
        display: flex;
        justify-content: center;
        width: 40%;

        svg {
          width: 50px;
          height: 50px;
          padding: 15px;
          background-color: rgba(get-color(black), 0.4);
          border-radius: 50%;
          overflow: visible;

          @include media-query(sm) {
            width: 64px;
            height: 64px;
            padding: 20px;
          }
        }
      }
    }
  }

  &__options {
    margin: 4px 0;

    &[data-poll-status="Finished"] {
      #{$this} {
        &__option {
          position: relative;
          justify-content: space-between;
          background-color: rgba(get-color(on-background-rgb), 0.07);

          &-bar {
            position: absolute;
            border-radius: 5px 0 0 5px;
            inset: 0;
            width: 0%;
            z-index: -1;
            background-color: rgba(get-color(on-background-rgb), 0.2);

            &--winning {
              background-color: get-color(primary);
            }
          }

          &--winning {
            background-color: rgba(get-color(primary-rgb), 0.6);
            color: get-color(on-primary);
          }
        }

        &__time {
          display: none;
        }
      }
    }

    &[data-poll-status="Live"] {
      #{$this} {
        &__option {
          border: 1px solid get-color(primary);
          justify-content: center;

          &-bar,
          &-votes {
            display: none;
          }
        }

        &__total-votes {
          display: none;
        }
      }
    }
  }

  &__option {
    padding: 8px 10px;
    border-radius: 5px;
    margin: 10px 0;
    display: flex;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__time {
    color: rgba(get-color(on-background-rgb), 0.6);
  }

  &__total-votes {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    color: rgba(get-color(on-background-rgb), 0.6);
  }

  &__locked {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(get-color(black), 0);
    opacity: 0;
    visibility: hidden;

    .button {
      display: flex;
      gap: 12px;

      &--large {
        padding: 10px 24px;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: transparent;
      }
    }
  }

  &__feedback {
    margin-top: 20px;
    color: rgba(get-color(on-background-rgb), 0.6);
  }

  &__feedback-container {
    display: flex;
    gap: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__count {
    font-size: font-size(14px);
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}

.locked,
.unavailable {
  .post-tile {
    &__locked {
      opacity: 1;
      visibility: visible;
    }
  }
}
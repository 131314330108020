.breadcrumbs {
  font-size: font-size(15px);
  color: get-color(text);

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-query(sm) {
      justify-content: flex-start;
    }
  }

  &__item {
    flex: 0 1 auto;

    & + & {
      margin-left: 10px;
    }

    &:not(:last-child) {
      &::after {
        content: '→';
        margin-left: 8px;
      }
    }

    &:first-child {
      display: none;

      @include media-query(sm) {
        display: block;
      }
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }

  &__current {
    color: get-color(heading);
  }
}

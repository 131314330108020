.checkbox-field {
  display: flex;
  align-items: center;

  &__input {
    margin-right: 10px;
  }

  &__label {
    cursor: pointer;
  }
}

// Needs refactoring
// stylelint-disable selector-max-compound-selectors, selector-max-specificity
// stylelint-disable selector-max-type
.markdown {
  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $spacer-4;
    margin-bottom: $spacer-3;
    font-weight: $font-weight-base-bold;
    line-height: $line-height-heading;

    .octicon-link {
      color: get-color(on-background);
      vertical-align: middle;
      visibility: hidden;
    }

    &:hover .anchor {
      text-decoration: none;

      .octicon-link {
        visibility: visible;
      }
    }

    tt,
    code {
      // stylelint-disable-next-line primer/spacing
      padding: 0 0.2em;
      font-size: inherit;
    }
  }

  h1 {
    // stylelint-disable-next-line primer/spacing
    padding-bottom: 0.3em;
    // stylelint-disable-next-line primer/typography
    font-size: 2em;
    border-bottom: $divider-border;
  }

  h2 {
    // stylelint-disable-next-line primer/spacing
    padding-bottom: 0.3em;
    // stylelint-disable-next-line primer/typography
    font-size: 1.5em;
    border-bottom: $divider-border;
  }

  h3 {
    // stylelint-disable-next-line primer/typography
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    // stylelint-disable-next-line primer/typography
    font-size: 0.875em;
  }

  h6 {
    // stylelint-disable-next-line primer/typography
    font-size: 0.85em;
    color: get-color(text);
  }

  summary {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;

      .anchor {
        // stylelint-disable-next-line primer/spacing
        margin-left: -40px;
      }
    }

    h1,
    h2 {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

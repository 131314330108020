.collection-template {
  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include media-query(sm) {
      margin-bottom: 48px;
    }
  }

  &__heading {
    font-size: font-size-heading(36px);

    @include media-query(sm) {
      font-size: font-size-heading(48px);
    }
  }
}

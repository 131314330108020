.drawer {
  $this: &;
  $width: 260px;
  $animation: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  position: fixed;
  z-index: 11;
  height: 100%;

  &__background {
    position: fixed;
    left: 0;
    top: 0;

    opacity: 0;
    transition: opacity #{$animation};

    width: 100%;
    height: 100%;

    pointer-events: none;

    background-color: rgba(get-color(background-rgb), 0.85);
  }

  &__content {
    display: flex;

    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;

    flex-direction: column;

    transition: transform #{$animation};

    width: 100%;
    height: 100%;

    overflow: auto;

    background-color: get-color(background);

    @include media-query(sm) {
      transform: translateX(480px);
      width: 480px;
    }

    &--small {
      left: 0;
      transform: translateX(-$width);
      width: $width;
    }
  }

  &__content-inner {
    padding: 24px 16px;
    width: 480px;
    max-width: 100%;
    height: auto;

    @include media-query(sm) {
      padding: 32px 40px;
    }
  }

  &--left {
    #{$this}__content {
      right: auto;
      left: 0;
      transform: translate(-100%, 0);
    }
  }

  &.visible {
    > #{$this}__background {
      opacity: 1;
      pointer-events: auto;
    }

    > #{$this}__content {
      transform: translateX(0);
    }

    #{$this}__checkbox.active + #{$this}__content {
      transform: translateX(0);
    }
  }
}

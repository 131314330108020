// stylelint-disable selector-max-type
.markdown .csv-data {
  td,
  th {
    // stylelint-disable-next-line primer/spacing
    padding: 5px;
    overflow: hidden;
    font-size: $font-size-small;
    line-height: $line-height-base;
    text-align: left;
    white-space: nowrap;
  }

  .blob-num {
    // stylelint-disable-next-line primer/spacing
    padding: 10px $spacer-2 9px;
    text-align: right;
    background: get-color(background-pale);
    border: 0;
  }

  tr { border-top: 0; }

  th {
    font-weight: $font-weight-base-bold;
    background: get-color(background-pale);
    border-top: 0;
  }
}

.static-page {
  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include media-query(sm) {
      margin-bottom: 48px;
    }
  }

  &__heading {
    font-size: font-size-heading(66px);

    @include media-query(sm) {
      font-size: font-size-heading(78px);
    }
  }
}

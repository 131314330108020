// stylelint-disable selector-max-type
// stylelint-disable selector-max-compound-selectors

.markdown {
  [data-footnote-ref] {
    &::before {
      content: '[';
    }

    &::after {
      content: ']';
    }
  }

  .footnotes {
    font-size: $font-size-base;
    color: get-color(text);
    border-top: $divider-border;

    ol {
      padding-left: $spacer-3;

      ul {
        display: inline-block;
        padding-left: $spacer-3;
        margin-top: $spacer-3;
      }
    }

    li {
      position: relative;
    }

    li:target::before {
      position: absolute;
      top: -$spacer-2;
      right: -$spacer-2;
      bottom: -$spacer-2;
      left: -$spacer-4;
      pointer-events: none;
      content: '';
      // stylelint-disable-next-line primer/borders
      border: 2px $divider-style get-color(divider);
      border-radius:  $img-border-radius;
    }

    li:target {
      color: get-color(on-background);
    }

    .data-footnote-backref g-emoji {
      font-family: monospace;
    }
  }
}

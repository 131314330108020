// Need to target base styles
// stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type
// stylelint-disable selector-max-type
.markdown {
  // Images & Stuff
  img {
    max-width: 100%;
    // because we put padding on the images to hide header lines, and some people
    // specify the width of their images in their markdown.
    box-sizing: content-box;
    background-color: get-color(background-pale);

    &[align='right'] {
      // stylelint-disable-next-line primer/spacing
      padding-left: 20px;
    }

    &[align='left'] {
      // stylelint-disable-next-line primer/spacing
      padding-right: 20px;
    }
  }

  .emoji {
    max-width: none;
    vertical-align: text-top;
    // Override `<img>` styles so Emojis don't clash with zebra striping in our tables
    background-color: transparent;
  }

  // Gollum Image Tags

  // Framed
  span.frame {
    display: block;
    overflow: hidden;

    > span {
      display: block;
      float: left;
      width: auto;
      // stylelint-disable-next-line primer/spacing
      padding: 7px;
      // stylelint-disable-next-line primer/spacing
      margin: 13px 0 0;
      overflow: hidden;
      border: $divider-border;
    }

    span img {
      display: block;
      float: left;
    }

    span span {
      display: block;
      // stylelint-disable-next-line primer/spacing
      padding: 5px 0 0;
      clear: both;
      color: get-color(on-background);
    }
  }

  span.align-center {
    display: block;
    overflow: hidden;
    clear: both;

    > span {
      display: block;
      // stylelint-disable-next-line primer/spacing
      margin: 13px auto 0;
      overflow: hidden;
      text-align: center;
    }

    span img {
      margin: 0 auto;
      text-align: center;
    }
  }

  span.align-right {
    display: block;
    overflow: hidden;
    clear: both;

    > span {
      display: block;
      // stylelint-disable-next-line primer/spacing
      margin: 13px 0 0;
      overflow: hidden;
      text-align: right;
    }

    span img {
      margin: 0;
      text-align: right;
    }
  }

  span.float-left {
    display: block;
    float: left;
    // stylelint-disable-next-line primer/spacing
    margin-right: 13px;
    overflow: hidden;

    span {
      // stylelint-disable-next-line primer/spacing
      margin: 13px 0 0;
    }
  }

  span.float-right {
    display: block;
    float: right;
    // stylelint-disable-next-line primer/spacing
    margin-left: 13px;
    overflow: hidden;

    > span {
      display: block;
      // stylelint-disable-next-line primer/spacing
      margin: 13px auto 0;
      overflow: hidden;
      text-align: right;
    }
  }
}

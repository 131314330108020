.select-field {
  $this: &;
  $dropdown-icon-width: 12px;

  position: relative;

  border: $input-border;
  border-radius: $input-border-radius;

  width: 100%;
  min-width: 80px;
  height: $input-height;

  background-color: get-color(input-background);

  font-family: $input-font-family;
  font-size: $input-font-size;
  line-height: $input-line-height;

  &:hover {
    border-color: get-color(input-border-hover);
  }

  &:focus-within {
    border-color: get-color(input-border-focus);
  }

  &__select {
    @include autofill-fix(get-color(input-text));

    display: block;

    top: 0;
    left: 0;

    transition: color $transition-base;

    -webkit-appearance: none;
    outline: none;

    border: 0;
    padding: 1.25em ($input-horizontal-padding * 2 + $dropdown-icon-width) 0
      $input-horizontal-padding;

    box-sizing: border-box;

    width: 100%;
    height: 100%;

    background: transparent;

    cursor: pointer;

    color: get-color(input-text);
    font-weight: inherit;
    font-style: inherit;
    line-height: $input-line-height;

    &--bold {
      font-weight: $font-weight-base-bold;
    }

    option {
      background-color: get-color(select-option);
    }
  }

  &__dropdown-icon {
    display: flex;
    align-items: center;

    position: absolute;

    top: 0;
    right: ($input-horizontal-padding + $input-border-width);

    pointer-events: none;

    opacity: 0.3;

    transform: translateX(2px);

    width: $dropdown-icon-width;
    height: 100%;
  }

  &__label {
    position: absolute;

    top: (($input-height * 0.5) - $input-border-width);
    left: $input-horizontal-padding;

    pointer-events: none;

    transform: translateY(-50%) translateY(-0.7em) scale(0.8);
    transform-origin: left center;

    transition: left $transition-base, transform $transition-base,
      color $transition-base;

    color: get-color(text-pale);
  }

  &__icon {
    display: flex;

    position: absolute;
    z-index: 1;

    top: -$input-border-width;
    left: $input-horizontal-padding;

    align-items: center;

    transform: translateY(-1px);

    width: $input-icon-width;
    height: $input-height;
  }

  &--base {
    #{$this}__select {
      padding-top: 0;
    }
  }

  &--icon {
    #{$this}__input {
      padding-left: (2 * $input-horizontal-padding + $input-icon-width);
    }

    #{$this}__label {
      left: (2 * $input-horizontal-padding + $input-icon-width);
    }
  }

  &--quantity {
    width: 90px;

    @include media-query(sm) {
      width: 120px;
    }
  }

  &.selected {
    border-color: get-color(primary);
  }

  &--expand {
    max-width: 100%;
    width: 100%;
  }

  &--rounded {
    border-radius: Min($input-border-radius, 20px);
    border: none;
  }

  &--non-transparent {
    background-color: rgba(get-color(on-background-rgb),0.1);
  }
}

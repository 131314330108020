.slider {
  $this: &;

  position: relative;
  overflow: hidden;

  // TNS slider slides spacing fix
  .tns-horizontal.tns-subpixel {
    display: flex;
  }

  [id$='-mw'].tns-ovh.tns-ah {
    height: 100% !important;
  }

  .tns-nav {
    display: none;

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    width: auto;

    justify-content: center;

    @include media-query(sm) {
      width: 100%;
    }

    button {
      border-radius: 100%;
      padding: 0;

      width: 15px;
      height: 15px;

      background: get-color(white);

      &.tns-nav-active {
        background: lighten(get-color(white), 50%);
      }

      & + button {
        margin-left: 10px;
      }
    }
  }

  .tns-outer {
    [data-action] {
      display: none;
    }
  }

  &__nav {
    display: none;
  }

  &__nav-item {
    position: relative;
    vertical-align: middle;

    /*transform: translateY(-50%);*/

    transition: opacity $transition-base;

    padding: 0 15px;
    border-radius: 50%;

    color: get-color(black);

    height: 40px;
    width: 40px;

    background: transparent;

    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);

    transition: color 0.25s;

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      border: 2px solid transparent;
      border-radius: 50%;
      width: 0;
      height: 0;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
    }

    $white: get-color(white);

    &:hover {
      color: $white;
    }

    &:hover::before,
    &:hover::after {
      width: 100%;
      height: 100%;
    }

    &:hover::before {
      border-top-color: $white;
      border-right-color: $white;
    }

    &:hover::after {
      border-bottom-color: $white;
      border-left-color: $white;
    }

    &::after {
      top: 0;
      left: 0;
    }

    &:hover::before {
      border-top-color: $white;
      border-right-color: $white;
    }

    &:hover::after {
      border-bottom-color: $white;
      border-left-color: $white;
    }

    &--prev {
      margin-right: 10px;
    }

    &--next {
      margin-left: 10px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__sections {
    img {
      width: 100%;
    }

    > * + * {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      height: 100%;
    }
  }

  &__content {
    position: relative;
  }

  &--has-dots-navigation {
    .tns-nav {
      display: flex;
      height: 44px;
      color: rgba(255, 255, 255, 0.5);
      justify-content: flex-end;
      align-items: center;
      padding-right: 25px;

      @include media-query(sm) {
        padding-right: 0;
        justify-content: center;
      }
    }
  }

  &--has-arrow-navigation {
    #{$this}__nav {
      @include media-query(sm){
        position: absolute;
        bottom: 15px;
        left: 15px;

        display: flex;
        justify-content: flex-start;

        width: 100%;

        z-index: 1;
      }
    }
  }

  &--has-mousedrag-navigation {
    .tns-item {
      cursor: grab;
    }
  }
}

.checkbox {
  $this: &;

  position: relative;

  &__input {
    display: none;

    &:checked + #{$this}__box {
      border-color: get-color(primary);
      background-color: get-color(primary);
    }
  }

  &__box {
    position: relative;

    border: $input-border;

    width: 20px;
    height: 20px;
  }

  &__icon {
    position: absolute;

    top: 0;
    left: 1px;

    width: 14px;
    height: 14px;

    color: get-color(on-primary);
  }
}

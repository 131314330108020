.newsletter {
  &__header {
    margin-bottom: 24px;
  }

  &__heading {
    text-align: center;
  }

  &__text {
    text-align: center;
  }

  &__form {
    margin-top: 32px;
  }
}

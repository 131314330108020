.body {
  position: relative;

  padding-bottom: 0;
  width: 100%;
  min-height: 100%;

  background-color: get-color(background);

  letter-spacing: $letter-spacing-base;

  // FIXME: Boilerplate
  &.no-scroll {
    overflow: hidden;
  }
}

@mixin media-query($breakpoint, $direction: '') {
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value from the map.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Check the query direction: default is min (mobile first approach).
    @if $direction == '' or $direction == min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    } @else if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint-value) {
        @content;
      }
    }

    // If the breakpoint doesn't exist in the map, we can use other, custom values.
  } @else {
    @if $direction == '' or $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint) {
        @content;
      }
    }
  }
}

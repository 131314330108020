.social-share {
  display: flex;
  align-items: center;

  margin: 30px 0 0;
  padding: 0;

  list-style-type: none;

  &__item {
    & + & {
      margin-left: 20px;
    }
  }
}

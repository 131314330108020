.radio {
  $this: &;

  &__input {
    display: block;

    position: relative;

    -moz-appearance: none;
    -webkit-appearance: none;

    border: $input-border;
    border-radius: 100%;

    outline: none;

    width: 20px;
    height: 20px;

    background-color: get-color(on-primary);

    &:checked {
      border-color: get-color(primary);
      background-color: get-color(primary);
    }

    &:after {
      content: '';

      display: block;
      position: absolute;

      top: 50%;
      left: 50%;

      transform: translateX(-50%) translateY(-50%);

      border-radius: 100%;

      width: 6px;
      height: 6px;

      background-color: get-color(on-primary);
    }
  }
}

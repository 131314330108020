.pagination {
  margin-top: 48px;
  display: flex;
  justify-content: center;

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    @include media-query(sm) {
      gap: 12px;
    }
  }

  &__item {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 4px;
    font-size: font-size(16px);
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: rgba(get-color(on-background-rgb), 0.05);
    color: get-color(on-background);

    @include media-query(sm) {
      width: 48px;
      height: 48px;
      font-size: font-size(18px);
    }

    &.disable {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:hover {
      color: rgba(get-color(on-background-rgb), 0.8);
    }

    &.active {
      background: get-color(on-background);
      color: get-color(background);

      &:hover {
        color: get-color(background);
      }
    }
  }
}
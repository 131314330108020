$range: (
  track-height: 2px,
  track-border-radius: 4px,
  track-border-width: 0,
  track-border: 0,
  track-bg: rgba(get-color(white), 0.8),
  track-bg-inactive: rgba(get-color(white), 0.4),
  track-box-shadow: none,
  track-width: 100%,
  track-cursor: pointer,
  thumb-height: 14px,
  thumb-width: 14px,
  thumb-bg: get-color(white),
  thumb-border-radius: 100%,
  thumb-box-shadow: none,
  thumb-cursor: grab,
  thumb-border: 0,
);

@mixin range-track() {
  box-shadow: map-get($range, 'track-box-shadow');
  border: map-get($range, 'track-border');
  width: map-get($range, 'track-width');
  height: map-get($range, 'track-height');

  cursor: map-get($range, 'track-cursor');
}

@mixin range-thumb() {
  position: relative;

  box-shadow: map-get($range, 'thumb-box-shadow');
  border: map-get($range, 'thumb-border');
  border-radius: map-get($range, 'thumb-border-radius');
  width: map-get($range, 'thumb-width');
  height: map-get($range, 'thumb-height');

  background: map-get($range, 'thumb-bg');
  cursor: map-get($range, 'thumb-cursor');
}

.range {
  width: 100%;
  height: 100%;

  &__input {
    width: 100%;
    height: 34px;
    // FIXME: Update once autoprefixer is fixed
    -webkit-appearance: none;

    background: none;
    cursor: map-get($range, 'track-cursor');
    vertical-align: bottom;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      @include range-track();

      border-radius: map-get($range, 'track-border-radius');
      -webkit-appearance: none;
      background: map-get($range, 'track-bg');
    }

    &::-webkit-slider-thumb {
      @include range-thumb();

      margin-top: (
          (
              map-get($range, 'track-border-width') * -2 +
                map-get($range, 'track-height')
            ) * 0.5
        ) - (map-get($range, 'thumb-height') * 0.5);
      -webkit-appearance: none;
    }

    &::-moz-range-track {
      @include range-track();

      border-radius: map-get($range, 'track-border-radius');
      background: map-get($range, 'track-bg');
    }

    &::-moz-range-thumb {
      @include range-thumb();
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-ms-track {
      @include range-track();

      border-width: map-get($range, 'thumb-width') 0;
      border-color: transparent;
      background: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      box-shadow: map-get($range, 'track-box-shadow');
      border: map-get($range, 'track-border');
      border-radius: map-get($range, 'track-border-radius');
      background: map-get($range, 'track-bg');
    }

    &::-ms-fill-upper {
      box-shadow: map-get($range, 'track-box-shadow');
      border: map-get($range, 'track-border');
      border-radius: map-get($range, 'track-border-radius');
      background: map-get($range, 'track-bg-inactive');
    }

    &::-ms-thumb {
      @include range-thumb();

      margin-top: 0;
    }
  }
}

.section-background {
  $this: &;

  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &__image,
  &__video,
  &__overlay {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__video {
    object-fit: cover;
  }

  &__image {
    display: flex;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    mix-blend-mode: none;
  }

  &--clip {
    $clip-full-length: calc(100% + 0.5px); // Blur fix

    &-square {
      &-top-left {
        clip-path: polygon(
          0 #{$clip-size},
          #{$clip-size} 0,
          #{$clip-full-length} 0,
          #{$clip-full-length} #{$clip-full-length},
          0 #{$clip-full-length}
        );
      }

      &-top-right {
        clip-path: polygon(
          0 0,
          calc(100% - #{$clip-size}) 0,
          #{$clip-full-length} #{$clip-size},
          #{$clip-full-length} #{$clip-full-length},
          0 #{$clip-full-length}
        );
      }

      &-bottom-right {
        clip-path: polygon(
          0 0,
          #{$clip-full-length} 0,
          #{$clip-full-length} calc(100% - #{$clip-size}),
          calc(100% - #{$clip-size}) #{$clip-full-length},
          0 #{$clip-full-length}
        );
      }

      &-bottom-left {
        clip-path: polygon(
          0 0,
          #{$clip-full-length} 0,
          #{$clip-full-length} #{$clip-full-length},
          #{$clip-size} #{$clip-full-length},
          0 calc(100% - #{$clip-size})
        );
      }

      &-top-left-bottom-right {
        clip-path: polygon(
          0 #{$clip-size},
          #{$clip-size} 0,
          #{$clip-full-length} 0,
          #{$clip-full-length} calc(100% - #{$clip-size}),
          calc(100% - #{$clip-size}) #{$clip-full-length},
          0 #{$clip-full-length}
        );
      }

      &-top-right-bottom-left {
        clip-path: polygon(
          0 0,
          calc(100% - #{$clip-size}) 0,
          #{$clip-full-length} #{$clip-size},
          #{$clip-full-length} #{$clip-full-length},
          #{$clip-size} #{$clip-full-length},
          0 calc(100% - #{$clip-size})
        );
      }

      &-all {
        clip-path: polygon(
          0 #{$clip-size},
          #{$clip-size} 0,
          calc(100% - #{$clip-size}) 0,
          #{$clip-full-length} #{$clip-size},
          #{$clip-full-length} calc(100% - #{$clip-size}),
          calc(100% - #{$clip-size}) #{$clip-full-length},
          #{$clip-size} #{$clip-full-length},
          0 calc(100% - #{$clip-size})
        );
      }
    }

    &-round {
      &-top-left {
        border-top-left-radius: $clip-size;
      }

      &-top-right {
        border-top-right-radius: $clip-size;
      }

      &-bottom-right {
        border-bottom-right-radius: $clip-size;
      }

      &-bottom-left {
        border-bottom-left-radius: $clip-size;
      }

      &-top-left-bottom-right {
        border-top-left-radius: $clip-size;
        border-bottom-right-radius: $clip-size;
      }

      &-top-right-bottom-left {
        border-top-right-radius: $clip-size;
        border-bottom-left-radius: $clip-size;
      }

      &-all {
        border-radius: $clip-size;
      }

      &-skew-bottom-right {
        border-bottom-right-radius: $clip-size;
      }
    }
  }
}

.tiers-modal {
  $animation: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &__header {
    max-width: calc(100% - 32px);
  }

  &__title {
    font-size: font-size(20px);
    font-weight: 700;
    font-family: $font-family-heading;
  }

  &__text {
    margin-top: 12px;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: rgba(get-color(on-background-rgb), 0.6);
      }
    }
  }

  &__quantity > p {
    margin-bottom: 12px;
  }

  &__options-container > p {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  &__content-inner {
    max-width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  }

  &__cta {
    display: grid;
    margin-top: 25px;
    text-align: center;
    gap: 16px;

    @include media-query(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: 32px;
      gap: 32px;
      align-items: center;
    }
  }

  &__cancel {
    color: get-color(on-background);
    text-decoration: underline;

    &:hover {
      color: rgba(get-color(on-background-rgb), .6);
    }
  }

  &__checkout {
    order: -1;

    @include media-query(sm) {
      order: initial;
    }
  }

  &__options {
    display:grid;
    gap: 16px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }

  &__option-label {
    padding: 8px;
    display: grid;
    position: relative;
    gap: 2px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    border-radius: Min($input-border-radius, 20px);
    background-color: rgba(get-color(on-background-rgb), 0.1);
    text-align: center;
    cursor: pointer;

    @include media-query(sm) {
      padding: 14px;
    }
  }

  &__option-input:checked + label {
    border: 2px solid get-color(primary);
  }

  &__option-price {
    font-size: font-size(20px);
    font-weight: 700;

    &--line-through {
      text-decoration: line-through;
      color: rgba(get-color(on-background-rgb), 0.6);
      font-size: font-size(16px);
      font-weight: 400;
    }
  }

  &__quantity {
    margin-top: 32px;
  }

  &__error {
    display: none;
    color: get-color(error);
    margin-left: 32px;
    font-size: $font-size-small;
    position: absolute;
    bottom: 0;
    left: 0;

    &.visible {
      display: block;
    }
  }

  &__quantity-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @include media-query(sm) {
      grid-template-columns: 1fr 1fr;
    }

    &--expand {
      grid-template-columns: 1fr;
    }
  }
}
.browser-ie11 {
  display: none;

  padding: 15px;
  width: 100%;
  height: 50px;

  background: rgba(40, 46, 67, 0.82);

  color: get-color(white);
  text-align: center;
}

// FIXME: Boilerplate
.edge-mobile {
  margin-right: -20px;
  margin-left: -20px;

  @include media-query(sm) {
    margin-right: 0;
    margin-left: 0;
  }
}
